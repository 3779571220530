import cn from "classnames";
import { cardTypesById } from "src/utils/constants/CardTypes";
import { renderTestId } from "src/utils/HelperFunctions";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AccountsReceivableManagementCard,
  ClosedInvoicesCard,
  CreditLimitCard,
  ErrorModal,
  GeneralLoader,
  IncomeCard,
  MinimumStockProductsCard,
  MoneyStockCard,
  OpenBidsCard,
  OpenOrdersCard,
  StockValueCard,
  SupplierDebtCard,
} from "src/components";
import {
  DataPointApplicationFeaturesCardsQueriesCardResponse,
  useGetCardsQuery,
  useGetCompaniesQuery,
} from "src/store/serviceApi";
import { LOCAL_STORAGE_KEY } from "src/utils/constants/Enums";

const KulcsUzletDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const { data: companiesData, isLoading: companiesAreLoading } =
    useGetCompaniesQuery();
  const { data: dataGetCards, isFetching } = useGetCardsQuery(
    { companyId: companyId ?? "" },
    { skip: Boolean(!companyId), refetchOnMountOrArgChange: true }
  );
  const isEmptyStateDisplay =
    !companiesAreLoading && companiesData?.length === 0;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const kulcsuzleturl = process.env.REACT_APP_KULCSUZLET_URL;

      if (event.origin !== kulcsuzleturl) {
        return;
      }
      const { type } = event.data;
      if (type === "LOGOUT") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // remove freshdesk widget on this page
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const widget = document.getElementById("launcher-frame");
      if (widget) {
        widget.style.display = "none";
        observer.disconnect();
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const sendScrollHeight = () => {
    const scrollHeight = document.body.scrollHeight;
    window.parent.postMessage({ scrollHeight }, "*");
  };

  useEffect(() => {
    sendScrollHeight();

    window.addEventListener("resize", sendScrollHeight);

    return () => {
      window.removeEventListener("resize", sendScrollHeight);
    };
  }, []);

  useEffect(() => {
    if (dataGetCards || isEmptyStateDisplay) {
      sendScrollHeight();
      localStorage.removeItem(LOCAL_STORAGE_KEY.KS_BD_GUID);
    }
  }, [isEmptyStateDisplay, dataGetCards]);

  const primaryCards = dataGetCards?.cards
    ? dataGetCards?.cards.filter(
        (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
          card.isLocked
      )
    : [];
  const secondaryCards = dataGetCards?.cards
    ? dataGetCards?.cards.filter(
        (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
          !card.isLocked
      )
    : [];

  const getColSpan = (type: number): 2 | 3 | 4 | 6 => {
    if (type === 1 && (primaryCards.length === 1 || primaryCards.length >= 3)) {
      return 6;
    } else if (type === 1 && primaryCards.length === 2) {
      return 4;
    } else if (
      (type === 2 || type === 3 || type === 4) &&
      primaryCards.length === 3
    ) {
      return 3;
    } else {
      return 2;
    }
  };

  const CardWrapper = ({ id }: { id: string }) => {
    const cardTypeNumber = cardTypesById[id];
    const isMobile = window?.innerWidth < 768;
    const cardTypes: Record<number, React.ReactNode> = {
      1: (
        <IncomeCard
          hasModal={false}
          span={
            isMobile ? 1 : isEmptyStateDisplay ? 6 : getColSpan(cardTypeNumber)
          }
        />
      ),

      5: <ClosedInvoicesCard hasModal={false} cardVariant="secondary" />,
      8: <OpenOrdersCard hasModal={false} cardVariant="secondary" />,
      9: <StockValueCard cardVariant="secondary" />,
      10: <OpenBidsCard hasModal={false} cardVariant="secondary" />,
    };
    return <>{cardTypes[cardTypeNumber]}</>;
  };

  return (
    <main
      {...renderTestId("dashboard-template")}
      className="flex flex-col items-center w-full md:mx-0 md:my-auto"
    >
      <div className="max-w-screen-2xl">
        <GeneralLoader isOpen={isFetching} />
        <section
          {...renderTestId("dashboard-top-section")}
          className="md:py-6 pb-3 md:pt-3 pt-0 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6"
        >
          {!isEmptyStateDisplay &&
            primaryCards &&
            primaryCards.map(
              (card: DataPointApplicationFeaturesCardsQueriesCardResponse) => (
                <CardWrapper key={card.id} id={card.id ?? ""} />
              )
            )}
          {isEmptyStateDisplay &&
            Object.keys(cardTypesById)
              .filter((id: string, index: number) => index < 3)
              .map((cardId: string) => (
                <CardWrapper key={cardId} id={cardId ?? ""} />
              ))}
        </section>
        <section
          {...renderTestId("dashboard-bottom-section")}
          className={cn(
            "p-4 md:px-10 md:py-6 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 rounded-3xl",
            { "bg-[#F4F9FF]": secondaryCards?.length > 0 },
            "secondary-cards-section"
          )}
        >
          {!isEmptyStateDisplay &&
            secondaryCards.map(
              (
                card: DataPointApplicationFeaturesCardsQueriesCardResponse,
                index: number
              ) => (
                <CardWrapper
                  key={card?.id ?? "" + index}
                  id={card?.id ?? "" + index}
                />
              )
            )}
        </section>
      </div>
      <ErrorModal />
    </main>
  );
};

export default KulcsUzletDashboard;
